import {
  AnalyticsPlugin,
  DatadogLog,
  DatadogRum,
  Reporter,
  FeatureFlags,
  MaskDirective,
  ResponsivePlugin,
  ScrollDirective,
  vFocus,
} from "@dayforward/common";

import { Head } from "@dayforward/common/src/composables";
import { createPinia } from "pinia";
import { createApp } from "vue";

import App from "./App.vue";
import { STORAGE_KEYS, useAccount, useSessionStorage, useUser } from "./composables";
import { ApolloPlugin, Descope } from "./plugins";
import { createAppRouter } from "./router";
import { useAuthStore } from "./store/auth.store";
// import { useUserDataStore } from "./store/modules/user-data.module.js";

// create app
const app = createApp(App);
app.use(createPinia());

// let reporterOptions = { provider: "console" };

const initializeRouter = () => {
  // create vue router
  app.use(createAppRouter(app));
};

const initializeApp = async () => {
  const authStore = useAuthStore();

  // Initialize descope
  app.use(Descope);

  // Check authentification session
  const { $descope } = app.config.globalProperties;

  $descope.client.onSessionChange(async (event, session) => {
    const { user } = useUser()
    if (!session) {
      authStore.setState("$authenticated", false)
      if (user.value) user.value = null 
    }
    else {
      authStore.setState("$authenticated", true)
      const { user, transform } = useUser()
      const { queryAccount, accountLoaded } = useAccount(app.config.globalProperties.$apollo);
      if (!accountLoaded.value && !user.value) {
        const { payload } = await queryAccount()
        user.value = transform(payload.value);
      }
    }
  })

  // Initialize services
  app.use(FeatureFlags, {
    provider: process.env?.VUE_APP_FLAGSMITH_API_KEY ? "flagsmith" : "noop",
    ...(process.env.VUE_APP_FLAGSMITH_API_KEY && { settings: { key: process.env.VUE_APP_FLAGSMITH_API_KEY }})
  });

  // segment / analytics
  let sUsrAg = navigator.userAgent;
  let analyticsOptions = { provider: "noop" };
  const isSegmentDisabled = process.env.VUE_APP_SEGMENT_DISABLED && process.env.VUE_APP_SEGMENT_DISABLED === "true";
  if (process.env.VUE_APP_SEGMENT_KEY && !isSegmentDisabled && sUsrAg.indexOf("HeadlessChrome") === -1) {
    analyticsOptions = {
      provider: "segment",
      apolloClient: app.config.globalProperties.$apollo,
      settings: {
        key: process.env.VUE_APP_SEGMENT_KEY,
      },
    };
  }
  app.use(AnalyticsPlugin, analyticsOptions);

  app.use(DatadogLog, {
    clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
    env: process.env.VUE_APP_RELEASE_STAGE,
    service: process.env.VUE_APP_DATADOG_SERVICE,
    version: process.env.VUE_APP_DATADOG_APP_VERSION,
    forwardReports: "all",
    forwardErrorsToLogs: true,
    forwardConsoleLogs: "all",
  });
 
  app.use(DatadogRum, {
    applicationId: process.env.VUE_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
    service: process.env.VUE_APP_DATADOG_SERVICE,
    env: process.env.VUE_APP_RELEASE_STAGE,
    version: process.env.VUE_APP_DATADOG_APP_VERSION,
    trackFrustrations: true,
    trackInteractions: true,
  });

  app.use(Reporter, {
    provider: "datadog",
    settings: {
      applicationId: process.env.VUE_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
      service: process.env.VUE_APP_DATADOG_SERVICE,
      env: process.env.VUE_APP_RELEASE_STAGE,
      version: process.env.DATADOG_APP_VERSION,
      trackFrustrations: true,
      trackInteractions: true,
    },
  });

  // Initialize router

  // If session set and store session id
  const { set } = useSessionStorage();

  const sessionId = authStore.id;
  set(STORAGE_KEYS.DF_SESSION_ID, { id: sessionId });
  app.use(ApolloPlugin, {
    sessionId,
    connectToDevTools: process.env.NODE_ENV === "development",
  });


  initializeRouter();

  // Setup other servcies
  app.use(ResponsivePlugin);

  // Initialize directives
  app.directive("scroll", ScrollDirective);
  app.directive("mask", MaskDirective);
  app.directive("focus", vFocus);

  app.component("Head", Head)
  app.mount("#app")
};

initializeApp();

// checkAuth(startApp);