import { datadogLogs } from "@datadog/browser-logs";

export default {
  install: (app, options) => {
    // console.log("Install datadog log plugin", options);
    const sUsrAg = navigator.userAgent;
    if (sUsrAg.indexOf("HeadlessChrome") === -1 && !["", "local"].includes(options.env)) {
      datadogLogs.init({
        ...options,
        site: "datadoghq.com",
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sampleRate: 100,
        trackInteractions: true,
        trackFrustrations: true,
        defaultPrivacyLevel: "mask-user-input",
        enableExperimentalFeatures: ["feature_flags", "clickmap"],
      });
      // datadogRum.startSessionReplayRecording();
    }
  }
};
