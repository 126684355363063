import { findByKey } from "@common";
import { useReporter } from "@common/composables";
import { useLocalStorage } from "@composables";
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import { getMarkers } from "@common/utils"

import { interviewStatus as STATUS, routeNames } from "../constants";
// import { acceptedNotice } from "../utils";

const from = ref(null);

/**
 * Initial composable to control redirects base on conditions
 * @returns {object}
 */
export function useRedirect() {
  const reporter = useReporter();
  const { get } = useLocalStorage();
  const route = useRoute();

  function _redirectAgentLed({ user, interview }) {
    // const { policy } = user;
    // if (policy && policy.status !== "PENDING") return { name: routeNames.PROFILE };

    const accepted = interview.questions.find(({ key }) => key === "ACCEPTED_NOTICES").answers[0];
    // Redirect agent-led applicants that have not accepted notice to demo consent page
    if (!accepted) return { name: routeNames.DEMO_APPLICANT_CONSENT, query: { interviewId: interview.id }};

    // Redirect agent-led applicants w accepted notice but no confirmed answers to demo review page
    const confirmed = get("df:agent_led:confirmed");
    if (accepted && !confirmed) return { name: routeNames.DEMO_APPLICANT_REVIEW, query: { interviewId: interview.id } };
    
    // Redirect agent-led applicants w accepted notice && confirmed answers to Waiting or Quote
    const { showQuote } = interview;
    if (accepted && confirmed && showQuote) return { name: routeNames.DEMO_APPLICANT_QUOTE };

    return { name: routeNames.DEMO_APPLICANT_WAITING };
  }

  // Global redirect method
  // @WIP This method is a work in progress and is the primary redirect method for providing a single sort of truth for routing
  function redirect({ user, step }) {
    const { name: to } = route;
    const markers = getMarkers(); // All available markers within an interview
    const interview = user.interviews?.length ? user.interviews[0] : null

    const { policy } = user
    // console.log("to", to);
    // Policy routing logic
    // If policy and user accesses interview routes or signin routes redirect to account overview
    if (policy) {
      // Default state
      if (to.includes("interview") || to.includes("signin")) return { route: { name: routeNames.PROFILE } };
    }
  
    // If there is
    let marker = null;
    if (interview) {
      const { status, markers: interviewMarkers } = interview;
      // Find and setup the interview marker
      if (interviewMarkers.length) {
        const interviewMarker = interviewMarkers.find(({ name }) => name === "last-step-completed");
        if (interviewMarker) {
          const key = findByKey("key", "step", interviewMarker.metadata);
          marker = key ? key.value : marker;
        }
      }
    
      // console.log("----", marker)
      // console.log(markers)
      // Regardless of state is ineligible or rejected route ot ineligible
      if ([STATUS.INELIGIBLE, STATUS.REJECTED].includes(status)) {
        reporter.log(`${status}: Routed to ineligible`);
        return { route: { name: routeNames.OFF_RAMP_INELIGIBLE } }
      }

      // Agent led interviews
      const { agentLed } = interview;
      if (agentLed) {
        const routeRedirect = _redirectAgentLed({ user, interview });
        return { route: routeRedirect }
      }

      // Interviews that are awaiting signature confirmation and user is accessing interview routes
      if (route.name.includes("interview") && status === STATUS.AWAITING_SIGNATURE_CONFIRMATION) {
        reporter.log(`${status}: Routed to thank you.`);
        return { route: { name: routeNames.INTERVIEW_THANKS } }
      }

      // const { quote } = user;
      // Routes that should ignore the marker
      const { showQuote } = interview;
      if (step) {
        if (step.name === routeNames.INTERVIEW_QUOTE && status !== STATUS.NEEDS_REVIEW && !showQuote) {
          return { name: routeNames.INTERVIEW_REVIEW };
        }
        // Redirect if is esign and needs review
        if (step.name === routeNames.INTERVIEW_E_SIGN && status === STATUS.NEEDS_REVIEW) {
          return { name: routeNames.ERROR_PAGE };
        }
    
        if (step.name === routeNames.INTERVIEW_E_SIGN && status === STATUS.NEEDS_REVIEW) {
          return { name: routeNames.MANUAL_UNDERWRITING };
        }
      }

      //@TODO Transition route redirects from interviewQuote to composable
      // Ready for checkout logic
      if (status === STATUS.READY_FOR_CHECKOUT) {
        if (!showQuote) {
          // reporter.log(`Routed to review - showQuote: ${showQuote}`);
          // return { name: routeNames.INTERVIEW_REVIEW };
        }
        if (showQuote) {}
      }
      // END

      // Manual underwritting logic
      // if (status === STATUS.NEEDS_REVIEW || status === STATUS.INCOMPLETE ) {
      //   reporter.log("Needs Review: Routed to manual underwritting");
      //   return { name: routeNames.MANUAL_UNDERWRITING };
      // }
      // END

      // Invalid conditions for quote logic
      //reporter.log(`Invalid conditions for interview: ${id}, status: ${status}`);
      // router.replace({ name: routeNames.INTERVIEW_INITIAL_STEP });
    }


    // Return a null value by default which will allow any addition logic
    return { route: null };
  }

  return {
    from,
    redirect,
  };
}

export const Redirect = defineComponent({
  name: "Redirect",
  setup() {
    const route = useRoute();
    const redirectBaseURL = process.env.VUE_APP_REDIRECT_BASE_URL || "https://app.dayforward.com";
    window.location = `${redirectBaseURL}${route.path}`;
    return () => {
      return null;
    };
  },
});
