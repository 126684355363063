// import { inject } from "vue";

import { ConsoleReporter, DatadogReporter } from "../reporter";

const ReporterKey = "ReporterKey";

// Not used anywhere I'm aware of at this time.
// export function useErrorReporter() {
//   return inject(ReporterKey);
// }

export const Reporter = {
  install: (app, options) => {
    // console.log("Install errors plugin", options);
    const { provider, settings } = options;

    const reporter = provider === "datadog" && settings ? new DatadogReporter(options) : new ConsoleReporter();

    if (!reporter) throw new Error("error reporting was not configured correctly");
  
    app.provide(ReporterKey, reporter);
    app.config.globalProperties.$reporter = reporter;
  },
};
