import { isEmpty, isFalse, isTrue } from "@common";
import { useReporter } from "@common/composables";
import { useInterview, useSchema } from "@composables";

import { interviewStatus as STATUS } from "../constants";
import { interviewInteraction } from "../constants";


export function useInterviewData() {
  const reporter = useReporter();
  // const { marker, setMarker } = useMarker();
  // const { user } = useUser();

  async function getInterviewQuestions(apollo, id) {
    const { queryQuestions } = useInterview(apollo);
    const { payload, success } = await queryQuestions(id);
    if (!success.value) {
      reporter.warn("WARNING: Unable to query product questions.");
      return;
    }
    const { questions } = payload.value.interview;
    // interviewQuestions.value = payload.value.interview.questions;
    const { generateSchema } = useSchema();
    generateSchema(questions);

    return { payload: questions,  success: true }
  }

  function hasInteractions(interview) {
    return "interactions" in interview && interview.interactions.length;
  }

  function receivedFinalQuote(interactions) {
    if (hasInteractions()) return interactions.some(({ type }) => type === interviewInteraction.RECEIVED_FINALIZED_QUOTE);
    return false;
  }

  function enteredPaymentInfo(interactions) {
    if (hasInteractions()) return interactions.some(({ type }) => type === interviewInteraction.PAYMENT_INFO_ENTERED);
    return false;
  }

  function setInterviewData(data) {
    // const { setPolicy } = usePolicy();
    if (!data) return;
    // interview.value = data;
    // user.value.interview = {
    //   ...user.value.interview,
    //   ...interview.value,
    // };
    // interviewId.value = interview.value.id;
    // interviewStatus.value = interview.value.status;
    // flags.value = interview.value.flags;
    // if (!isEmpty(user.value.policyID)) setPolicy(user.value.interview.policy);
  }

  function quoteStatus({ status, marker, quote }) {
    return (
      status === STATUS.READY_FOR_CHECKOUT &&
      marker === "quote" &&
      isFalse(quote?.isEstimate) &&
      isFalse(quote?.locked)
    );
  }

  
  function invalidPaymentStatus({ status, marker, quote, interactions }) {
    const invalidStatus =
      status === STATUS.READY_FOR_CHECKOUT &&
      marker === "payment" &&
      isFalse(quote?.isEstimate) &&
      isFalse(quote?.locked) &&
      isFalse(receivedFinalQuote(interactions)) &&
      isFalse(enteredPaymentInfo(interactions));
    
    // if (invalidStatus) marker.value = "quote"
    return invalidStatus;
  }

  // This might not be needed anymore
  function paymentStatus({ status, marker, interactions }) {
    return (
      status === STATUS.READY_FOR_CHECKOUT &&
      marker === "payment" &&
      isTrue(receivedFinalQuote(interactions)) &&
      isFalse(enteredPaymentInfo(interactions))
    );
  }

  return {
    getInterviewQuestions,
    invalidPaymentStatus,
    paymentStatus,
    quoteStatus,
    setInterviewData,
  };
}
